import { Context } from '@nuxt/types';
import {
  routeHasOptionValue,
  RouteStub,
} from '~/src/core/helpers/routerHelper';
import { userQuery } from '~/src/core/_/auth/graphql/user';

/**
 * Middleware that forces user to be authed/not authed to have access to a page/layout
 */
async function authMiddleware(ctx: Context): Promise<void> {
  // vue-router types aren't perfect, so we have to rely on our own route type stub
  const routeStubTyped = ctx.route as unknown as RouteStub;

  const isAuthDisabled = routeHasOptionValue(routeStubTyped, 'auth', false);
  const isGuestOnly = routeHasOptionValue(routeStubTyped, 'auth', 'guest');

  // If we shouldn't check for auth, then return
  if (isAuthDisabled) return;

  // Execute auth check query
  const { error, errors, data } = await ctx.app.apolloProvider.defaultClient.query({
    query: userQuery,
    fetchPolicy: 'cache-first',
  });

  if (error) {
    throw error;
  }

  if (errors) {
    throw errors;
  }

  const isLoggedIn = !!data.user?.id;

  if (isLoggedIn && isGuestOnly) {
    // If guest only, redirect away to home page
    ctx.redirect('/');
  } else if (!isLoggedIn && !isGuestOnly) {
    // If not logged in, redirect to login page
    ctx.redirect('/login');
  }
};
export default authMiddleware;
