






































import { vueWithMixins } from '~/src/core/types/coreUtilityTypes';
import { UserMixin } from '~/src/core/_/auth/mixins/userMixin';
import { fetchFulfilledProductCount } from '~/src/meta/repository/ordersRepo';

export default vueWithMixins<typeof UserMixin>().extend({
  mixins: [UserMixin],
  data() {
    return { a: 1, fulfilledProductCount: 38700000 };
  },
  async fetch() {
    if (this.user === null) {
      return;
    }

    const fulfilledProductCountRes = await fetchFulfilledProductCount(
      this.$apolloProvider.defaultClient
    );
    if (!fulfilledProductCountRes) return;
    this.fulfilledProductCount = fulfilledProductCountRes;
  },
});
