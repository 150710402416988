




























import Vue from 'vue';
import { Container } from 'inversify';
import {
  dropdownOptions,
  IDropdownOptions,
} from '~/src/components/layout/accountDropdown/optionsData';
import { GQLFetchRequest } from '~/src/common/gqlFetchRequest';

export default Vue.extend({
  name: 'UserMenu',
  data() {
    return {
      options: [] as Array<IDropdownOptions>,
    };
  },
  created(): void {
    this.options = dropdownOptions;
  },
  methods: {
    handleActions(action: (container: Container) => string): string {
      return action(this.$container);
    },
    handleGQLRequest(makeRequest: GQLFetchRequest<void> | undefined): void {
      if (!makeRequest) return;
      makeRequest(this.$apolloProvider.defaultClient);
    },
  },
});
