import { Container } from 'inversify';
import Vue, { VueConstructor } from 'vue';

export type Maybe<T> = T | null;

export type Optional<T> = T | undefined;

export type Nuxt = {
  $container: Container;
  [key: string]: unknown;
};

/**
 * Type Vue so that TypeScript understands that it has mixins
 */
export type VueWithMixins<
  A extends VueConstructor = VueConstructor,
  B extends VueConstructor = VueConstructor,
  C extends VueConstructor = VueConstructor,
  D extends VueConstructor = VueConstructor,
  E extends VueConstructor = VueConstructor
> = VueConstructor<
  Vue &
    InstanceType<A> &
    InstanceType<B> &
    InstanceType<C> &
    InstanceType<D> &
    InstanceType<E>
>;

/**
 * Some utility functions to ease work with types
 */

export function isTruthy<T>(val: T | undefined | null): val is T {
  return !!val;
}

/**
 * Get Vue correctly cast to include mixins
 */
export function vueWithMixins<
  A extends VueConstructor = VueConstructor,
  B extends VueConstructor = VueConstructor,
  C extends VueConstructor = VueConstructor,
  D extends VueConstructor = VueConstructor,
  E extends VueConstructor = VueConstructor
>(): VueWithMixins<A, B, C, D, E> {
  return Vue as VueWithMixins<A, B, C, D, E>;
}
