import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e465c842 = () => interopDefault(import('../pages/apps/index.vue' /* webpackChunkName: "pages/apps/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3b011b86 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _c3c5cef2 = () => interopDefault(import('../pages/tokens/index.vue' /* webpackChunkName: "pages/tokens/index" */))
const _46080f95 = () => interopDefault(import('../pages/apps/add-new-app.vue' /* webpackChunkName: "pages/apps/add-new-app" */))
const _6485ddd6 = () => interopDefault(import('../pages/tokens/add-new-token.vue' /* webpackChunkName: "pages/tokens/add-new-token" */))
const _8942ebf2 = () => interopDefault(import('../pages/apps/_id.vue' /* webpackChunkName: "pages/apps/_id" */))
const _6d5590af = () => interopDefault(import('../pages/tokens/_id.vue' /* webpackChunkName: "pages/tokens/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apps",
    component: _e465c842,
    name: "apps"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/news",
    component: _3b011b86,
    name: "news"
  }, {
    path: "/tokens",
    component: _c3c5cef2,
    name: "tokens"
  }, {
    path: "/apps/add-new-app",
    component: _46080f95,
    name: "apps-add-new-app"
  }, {
    path: "/tokens/add-new-token",
    component: _6485ddd6,
    name: "tokens-add-new-token"
  }, {
    path: "/apps/:id",
    component: _8942ebf2,
    name: "apps-id"
  }, {
    path: "/tokens/:id",
    component: _6d5590af,
    name: "tokens-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
