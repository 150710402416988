import { NuxtOptions } from '@nuxt/types';
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';
import { has } from 'lodash';
import { BootstrapError } from '~/src/core/errors/baseErrors';

/**
 * Wraps runtimeConfig object w/ ready validated getters for known values
 */
export class SmartRuntimeConfig {
  static ENVIRONMENT_DEV = 'dev';
  static ENVIRONMENT_STAGING = 'staging';
  static ENVIRONMENT_PROD = 'prod';

  private config: NuxtRuntimeConfig;

  constructor(config: NuxtRuntimeConfig) {
    this.config = config;
  }

  /**
   * Check if app built in dev environment
   */
  get isDevEnv(): boolean {
    return this.environment === SmartRuntimeConfig.ENVIRONMENT_DEV;
  }

  get environment(): string {
    if (!this.config.ENVIRONMENT) {
      throw new BootstrapError('Undefined environment env var!');
    }

    return this.config.ENVIRONMENT as string;
  }

  get hostname(): string {
    if (!this.config.APP_HOSTNAME) {
      throw new BootstrapError('Undefined app hostname!');
    }

    return this.config.APP_HOSTNAME as string;
  }

  get baseUrl(): string {
    if (!this.config.BASE_URL) {
      throw new BootstrapError('Undefined base URL!');
    }

    const baseUrl: string = this.config.BASE_URL as string;
    const hostname = this.hostname;

    return baseUrl.replace('{APP_HOSTNAME}', hostname);
  }

  get oauthBaseUrl(): string {
    if (!this.config.PF_OAUTH_BASE_URL) {
      throw new BootstrapError('Undefined PF OAuth API base url!');
    }

    return this.config.PF_OAUTH_BASE_URL as string;
  }

  get oauthClientId(): string {
    if (!this.config.PF_OAUTH_CLIENT_ID) {
      throw new BootstrapError('Undefined OAuth client id!');
    }

    return this.config.PF_OAUTH_CLIENT_ID as string;
  }

  get oauthClientSecret(): string {
    if (!this.config.PF_OAUTH_CLIENT_SECRET) {
      throw new BootstrapError('Undefined OAuth client secret!');
    }

    return this.config.PF_OAUTH_CLIENT_SECRET as string;
  }

  get graphQLApiUrl(): string {
    if (!this.config.GRAPHQL_API_URL) {
      throw new BootstrapError('Undefined GRAPHQL_API_URL env var!');
    }

    return this.config.GRAPHQL_API_URL as string;
  }

  get shouldEnablePfXdebug(): boolean {
    if (!this.config.PF_ENABLE_XDEBUG) {
      return false;
    }

    return this.config.PF_ENABLE_XDEBUG === true;
  }

  get cloudFlareBypassHash(): string | null {
    if (!this.config.PF_CF_BYPASS_HASH) {
      return null;
    }

    return this.config.PF_CF_BYPASS_HASH as string;
  }

  get gtm(): { id: string } {
    if (!this.config.gtm) {
      throw new BootstrapError('Undefined gtm environment variable!');
    }
    return this.config.gtm as { id: string };
  }
}

/**
 * Type guard for checking if user has passed in Nuxt config
 */
function isNuxtOptions(config: Record<string, unknown>): config is NuxtOptions {
  return (
    has(config, 'publicRuntimeConfig') || has(config, 'privateRuntimeConfig')
  );
}

/**
 * Wrap runtimeConfig or Nuxt config with useful utility functions for accessing runtimeConfig values
 * @param config Nuxt runtimeConfig
 */
export function config(
  config: NuxtRuntimeConfig | NuxtOptions
): SmartRuntimeConfig {
  const runtimeConfig: NuxtRuntimeConfig = isNuxtOptions(config)
    ? { ...config.publicRuntimeConfig, ...config.privateRuntimeConfig }
    : config;

  return new SmartRuntimeConfig(runtimeConfig);
}
