







































import Vue from 'vue';
import { Maybe, PrintfulCustomer } from '~/src/core/graphql/generated/types';
import { userQuery } from '~/src/core/_/auth/graphql/user';

type Error = {
  statusCode: number,
  message: string,
}

export default Vue.extend({
  layout: 'login',
  props: {
    error: {
      type: Object as () => Error,
      default: {} as Error,
    },
  },
  head: {
    title: 'Error',
  },
  computed: {
    title(): string {
      if (this.error.statusCode >= 500) {
        return 'Uh oh, something went wrong..';
      }
      return 'Oops!<br> this is awkward.';
    },
    description(): string {
      if (this.error.statusCode >= 500) {
        return 'Something went wrong on our side, please try again after a moment. If the issue persists, please contact support';
      }
      return "See, the page you're looking for doesn't exist";
    },
    currentYear(): number {
      return new Date().getFullYear()
    },
  },
  methods: {
    goHome(): void {
      const data: Maybe<{ user: PrintfulCustomer }> =
        this.$apolloProvider.defaultClient.cache.readQuery({
          query: userQuery,
        });

      if (!data?.user) {
        location.reload();
      }
      void this.$router.push('/');
    },
  },
});
