import https from 'https';
import { ApolloLink, createHttpLink } from '@apollo/client/core';
import { NuxtApolloModuleOptionsResolver } from '@ideabits/nuxt-apollo-module';
import { Context } from '@nuxt/types';
import cookie from 'cookie';
import { setContext } from '@apollo/client/link/context';
import {
  buildFullErrorMetadata,
  FullApolloErrorMetadata,
} from '~/src/core/helpers/graphqlHelper';
import { config } from '~/src/core/utils/smartRuntimeConfig';
import { ACCESS_TOKEN_COOKIE_NAME } from '~/src/core/_/auth/helpers/globalAuthHelper';
import { ClientAuthService } from '~/src/core/_/auth/services/clientAuthService';

/**
 * Build ApolloLink for the client
 */
function buildLink(ctx: Context): ApolloLink {
  const { $config } = ctx;
  const {
    graphQLApiUrl,
    isDevEnv,
    shouldEnablePfXdebug,
    cloudFlareBypassHash,
  } = config($config);

  // Ignoring SSL issues in dev envs
  const fetchOptions: Record<string, unknown> = {};
  if (process.server && isDevEnv) {
    fetchOptions.agent = new https.Agent({ rejectUnauthorized: false });
  }

  // Create http link
  const httpLink = createHttpLink({
    uri: graphQLApiUrl,
    credentials: 'include',
    fetchOptions,
  });

  const authLink = setContext((_, { headers }) => {
    const castHeaders = headers as Record<string, string>;
    if (process.client) {
      return { headers: castHeaders };
    }

    // Get access token from cookie
    const { req } = ctx;
    const cookies = req.headers.cookie ? cookie.parse(req.headers.cookie) : {};
    const token = cookies[ACCESS_TOKEN_COOKIE_NAME] || null;

    return {
      headers: {
        ...castHeaders,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...(shouldEnablePfXdebug ? { Cookie: 'XDEBUG_SESSION=PHPSTORM;' } : {}),
        ...(cloudFlareBypassHash
          ? { 'x-cf-bypass': cloudFlareBypassHash }
          : {}),
      },
    };
  });

  return authLink.concat(httpLink);
}

/**
 * Check whether error response was caused due to lack of authentication
 */
function hasAuthError(errInfo: FullApolloErrorMetadata): boolean {
  return (errInfo.operationErrors || []).some((opErr) =>
    /you must be logged in/i.exec(opErr.message)
  );
}

/**
 * Build Apollo options
 */
const optionsResolver: NuxtApolloModuleOptionsResolver = (ctx) => {
  const authService = ctx.$container.get(ClientAuthService);
  const invalidateEndpoint = authService.getOauthInvalidateUrl();

  return {
    clientOptions: {
      link: buildLink(ctx),
    },
    errorHandler(err) {
      const errorInfo = buildFullErrorMetadata(err);
      const isAuthError = hasAuthError(errorInfo);

      // Auth error was encountered, this shouldn't happen, so invalidating auth and redirecting home
      if (isAuthError) {
        ctx.redirect(invalidateEndpoint);
      }
    },
  };
};

export default optionsResolver;
