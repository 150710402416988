














































import Vue from 'vue';
import { Page, menu, outerLinks } from '~/src/components/layout/menuData';

export default Vue.extend({
  name: 'SideMenu',
  data() {
    return {
      menu: [] as Array<Page>,
      outerLinks: [] as Array<Page>,
    };
  },
  watch: {
    $route() {
      this.setCurrentPage(this.$route.name);
    },
  },
  created() {
    this.menu = menu;
    this.outerLinks = outerLinks;
    this.setCurrentPage(this.$route.name);
  },
  methods: {
    setCurrentPage(route: string | null | undefined): void {
      if (route) {
        const url = route.split('-') as [string];
        const root = url[0];

        this.menu.forEach((page) => {
          page.current = false;
          if (page.id === root) {
            page.current = true;
          }
        });
      }
    },
    closeMenu() {
      this.$emit('clicked');
    },
  },
});
