/**
 * The real typings off vue-router are invalid, so using these stubs:
 */

import { Context } from '@nuxt/types';
import { Route } from 'vue-router';

interface VueComponentStub {
  options: Record<string, unknown>;
  _Ctor: VueComponentStub;
}

type MatchedRouteStub = { components: VueComponentStub[] };

export type RouteStub = { matched: MatchedRouteStub[] };

/**
 * Check if the Vue component of the specified route has the specified option value
 * @returns
 */
export function routeHasOptionValue(
  route: RouteStub,
  key: string,
  value: string | boolean
): boolean {
  return route.matched.some((m) => {
    if (process.client) {
      // Client
      return Object.values(m.components).some(
        (component) => component.options && component.options[key] === value
      );
    } else {
      // SSR
      return Object.values(m.components).some((component) =>
        Object.values(component._Ctor).some(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (ctor) => ctor.options && ctor.options[key] === value
        )
      );
    }
  });
}

/**
 * Check whether a route has matches
 */
export function routeHasMatches(route: Route): boolean {
  return route.matched.some((m) => m.components.length > 0);
}

/**
 * Normalize path (get rid of query string, remove base path, strip redundant slashes etc.)
 */
export function normalizePath(path = '', ctx?: Context): string {
  // Remove query string
  let result = path.split('?')[0];

  // Remove base path
  if (ctx && ctx.base) {
    result = result.replace(ctx.base, '/');
  }

  // Remove redundant / from the end of path
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1);
  }

  // Remove duplicate slashes
  result = result.replace(/\/+/g, '/');

  return result;
}

/**
 * Check whether we're currently at the specified path
 */
export function isAppAtPath(path: string, ctx: Context): boolean {
  return normalizePath(ctx.route.path, ctx) === normalizePath(path, ctx);
}
