import { Context } from '@nuxt/types';
import { injectable, inject } from 'inversify';
import { NuxtContextType } from '~/src/core/helpers/globalIocTypes';
import { config } from '~/src/core/utils/smartRuntimeConfig';

/**
 * This is an example of how to use the IoC container to resolve things like the Nuxt context from any service in the container.
 * If you don't need access to globals, you can just do functional services instead without having to create classes.
 *
 * You could just access Nuxt globals from the Vue component directly, but this breaks separation of concerns:
 * All business logic (at least all logic that depends on app configs etc.) must be written inside the Vue components as methods
 */

/**
 * Service for doing FE auth work
 */
@injectable()
export class ClientAuthService {
  #ctx: Context;

  constructor(@inject(NuxtContextType) ctx: Context) {
    this.#ctx = ctx;
  }

  /**
   * Get OAuth flow URL query params
   */
  private getOauthQueryParams(): URLSearchParams {
    const { oauthClientId } = config(this.#ctx.$config);

    const queryParams = new URLSearchParams();
    queryParams.set('client_id', oauthClientId);
    queryParams.set('redirect_url', this.getOauthRedirectUrl());

    return queryParams;
  }

  /**
   * Create OAuth flow URL
   */
  private createOauthUrl(oauthParams: URLSearchParams): string {
    const { oauthBaseUrl } = config(this.#ctx.$config);

    const url = new URL('/oauth/authorize', oauthBaseUrl);
    url.search = oauthParams.toString();

    return url.toString();
  }

  /**
   * Get OAuth flow Login URL
   */
  getOauthLoginUrl(): string {
    return this.createOauthUrl(this.getOauthQueryParams());
  }

  /**
   * Get OAuth flow Sign Up URL
   */
  getOauthSignUpUrl(): string {
    const queryParams = this.getOauthQueryParams();
    queryParams.set('signUp', '1');

    return this.createOauthUrl(queryParams);
  }

  /**
   * Get OAuth flow redirect URL
   */
  getOauthRedirectUrl(): string {
    const { baseUrl } = config(this.#ctx.$config);
    return `${baseUrl}api/oauthCallback`;
  }

  /**
   * Get API endpoint for invalidating server OAuth cookie
   */
  getOauthInvalidateUrl(): string {
    const { baseUrl } = config(this.#ctx.$config);
    return `${baseUrl}api/oauthInvalidate`;
  }
}
