import Vue from 'vue';
import {
  AllUserInfoFragment,
  PrintfulCustomer,
} from '~/src/core/graphql/generated/types';
import { Maybe } from '~/src/core/types/coreUtilityTypes';
import { userQuery } from '~/src/core/_/auth/graphql/user';

/**
 * Mixins that make it easier to work with the active user object
 */
export const UserMixin = Vue.extend({
  data(): { user: Maybe<AllUserInfoFragment> } {
    return {
      user: null as PrintfulCustomer | null,
    };
  },
  apollo: {
    user: {
      query: userQuery,
    },
  },
  computed: {
    /**
     * Check if the active user is logged in
     */
    isLoggedIn(): boolean {
      return !!(this.user && this.user.id);
    },
  },
});
