export interface ILink {
  href: string;
  title: string;
}

export const LinksData: Array<ILink> = [
  {
    href: 'https://www.printful.com/services',
    title: 'Services',
  },
  {
    href: 'https://www.printful.com/integrations',
    title: 'Integrations',
  },
  {
    href: 'https://www.printful.com/shipping',
    title: 'Shipping',
  },
  {
    href: 'https://www.printful.com/policies',
    title: 'Policies',
  },
  {
    href: 'https://www.printful.com/feature-request',
    title: 'Feature Requests',
  },
  {
    href: 'https://www.printful.com/blog',
    title: 'Blog',
  },
  {
    href: 'https://help.printful.com/hc/en-us',
    title: 'FAQ',
  },
];
