import 'reflect-metadata';
import { Plugin } from '@nuxt/types';
import { userQuery } from '~/src/core/_/auth/graphql/user';
import { ClientAuthService } from '~/src/core/_/auth/services/clientAuthService';
import { asyncTimeout } from '~/src/core/helpers/promiseHelper';
import { GetUserDataQuery } from '~/src/core/graphql/generated/types';

/**
 * Client-side plugin that checks if we haven't run into a situation where we're logged in on the SSR server (through the OAuth cookie), but
 * not logged in when app is running on the client-side (logged out from PF Core session).
 *
 * If this happens, we're redirecting the user to an auth invalidation endpoint
 */
const plugin: Plugin = async (ctx) => {
  // Execute auth check query
  const userResults =
    await ctx.app.apolloProvider.defaultClient.query<GetUserDataQuery>({
      query: userQuery,
      fetchPolicy: process.server ? 'cache-first' : 'cache-only', // If we're on the client-side, we should only retrieve the SSR cached value here
    });

  const isLoggedInOnServer = !!userResults.data?.user?.id;

  // If we're on the server-side, quit here - we've loaded the auth status and cached in
  if (process.server) {
    return;
  }

  // Apollo ssrForceFetchDelay timeout (100) + safety buffer (50), so that fetchPolicy no-cache can be used
  // Not using await so that this doesn't block the execution of the request
  void asyncTimeout(150).then(() => {
    // We're in the client-side and we have the value of the `user` query as it was resolved from the SSR server
    // Now let's re-fetch the value from server to see if there's a mismatch between client-side/server-side auth
    // Let's not await it too, so that this doesn't block the execution flow
    void ctx.app.apolloProvider.defaultClient
      .query<GetUserDataQuery>({
        query: userQuery,
        fetchPolicy: 'no-cache',
      })
      .then((freshUserResults) => {
        const isLoggedInOnClient = !!freshUserResults.data?.user?.id;
        if (isLoggedInOnServer && !isLoggedInOnClient) {
          // Redirect to endpoint that invalidates server auth

          // Get URL for invalidation endpoint
          const endpointUrl = ctx.$container
            .get(ClientAuthService)
            .getOauthInvalidateUrl();

          // Current location
          const currentUrl = ctx.route.path;

          const searchParams = new URLSearchParams();
          searchParams.set('redirectPath', currentUrl);

          const redirectUrl = new URL(endpointUrl);
          redirectUrl.search = searchParams.toString();

          ctx.redirect(redirectUrl.toString());
        }
      });
  });
};
export default plugin;
