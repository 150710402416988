
































































import Vue from 'vue';
import { SocialMediaData } from '~/src/components/layout/socialMediaData';
import { LinksData } from '~/src/components/layout/linksData';

export default Vue.extend({
  name: 'Footer',
  props: {
    fulfilledProductCount: {
      type: Number,
      required: true,
      default: 38700000,
    },
  },
  data() {
    return {
      socialMedia: SocialMediaData,
      links: LinksData,
    };
  },
  computed: {
    formattedProductCount() {
      const absoluteFulfilledProductCount = Math.abs(
        Number(this.fulfilledProductCount)
      );
      return absoluteFulfilledProductCount >= 1.0e9
        ? `${(absoluteFulfilledProductCount / 1.0e9).toFixed(1)}B`
        : absoluteFulfilledProductCount >= 1.0e6
        ? `${(absoluteFulfilledProductCount / 1.0e6).toFixed(1)}M`
        : absoluteFulfilledProductCount >= 1.0e3
        ? `${(absoluteFulfilledProductCount / 1.0e3).toFixed(1)}K`
        : absoluteFulfilledProductCount;
    },
    currentYear(): number {
      return new Date().getFullYear()
    },
  },
});
