import { gql } from '@apollo/client/core';

/**
 * Fragment for retrieving all user data from a PrintfulCustomer
 */
export const allUserInfoFragment = gql`
  fragment allUserInfo on PrintfulCustomer {
    id
    customerId
    environmentId
    fullName
  }
`;

/**
 * Query for retrieving all user info
 */
export const userQuery = gql`
  query getUserData {
    user {
      ...allUserInfo
    }
  }

  ${allUserInfoFragment}
`;
