
































































import Vue from 'vue';
import { mdiClose, mdiAccount } from '@mdi/js';

export default Vue.extend({
  name: 'Drawer',
  props: {
    icon: {
      type: Object,
      required: true,
    },
    isOptionsMenu: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    userName: {
      type: String,
      required: false,
      default: 'User',
    },
  },
  data() {
    return {
      isOpen: false,
      closeIcon: mdiClose,
      accountIcon: mdiAccount,
    };
  },
  computed: {
    asideClasses(): string {
      if (this.isOptionsMenu) {
        return this.isOpen ? '-translate-x-0' : 'translate-x-full';
      }

      return this.isOpen ? 'translate-x-0' : '-translate-x-full';
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.client) {
          if (isOpen) document.body.style.setProperty('overflow', 'hidden');
          else document.body.style.removeProperty('overflow');
        }
      },
    },
  },
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },
  },
});
