import { gql } from '@apollo/client/core';

export const getTotalFulfilledProductCount = gql`
  query getTotalFulfilledProductCount {
    devPortal {
      devPortalPrintfulMetadata {
        fulfilledProductCount
      }
    }
  }
`;
