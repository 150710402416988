import 'reflect-metadata';
import { Plugin, Context } from '@nuxt/types';
import { Container } from 'inversify';
import * as Types from '~/src/core/helpers/globalIocTypes';

interface RequestContainerInitData {
  nuxtContext: Context;
}

/**
 * Create new IoC container for request scope
 */
export function createRequestContainer(
  initData: RequestContainerInitData
): Container {
  const { nuxtContext } = initData;
  const container = new Container({
    autoBindInjectable: true,
    defaultScope: 'Request',
  });

  // Request specific bindings
  container.bind<Context>(Types.NuxtContextType).toConstantValue(nuxtContext);

  return container;
}

/**
 * Plugin that creates an Inversify IoC container for each request
 */
const plugin: Plugin = (ctx, inject) => {
  // Create new container
  const container = createRequestContainer({ nuxtContext: ctx });

  // Inject into Vue components
  inject('container', container);
};
export default plugin;
