/**
 * Definitions for various global InverisifyJS dependency type constants
 */

/**
 * REQUEST SCOPE TYPES
 */

/**
 * Nuxt $context
 */
export const NuxtContextType = Symbol.for('NuxtContext');

/**
 * SERVER PROCESS TYPES
 */

/**
 * Nuxt app itself
 */
export const NuxtAppType = Symbol.for('NuxtApp');

/**
 * NuxtOptions
 */
export const NuxtOptionsType = Symbol.for('NuxtOptions');
