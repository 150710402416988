import {
  getRequiredValidationMessage,
  REQUIRED_MESSAGE,
} from '~/src/common/commonValidation';
import { TokenValidationFunction } from '~/src/tokens/validationTypes';
import { Maybe } from '~/src/core/types/coreUtilityTypes';
import { STORE_LEVEL_ACCESS_TYPE } from '~/src/components/tokens/accessLevels';
import { PrintfulStoreConnection } from '~/src/core/graphql/generated/types';

const getStoreIdValidationMessage = (
  storeId: Maybe<number> | undefined
): Maybe<string> => (!storeId ? REQUIRED_MESSAGE : null);

const getWhiteListedDomainsValidationMessage = (
  whiteListedDomains: string[] | null | undefined
) => {
  if (!whiteListedDomains) return null;
  return whiteListedDomains.length
    ? getRequiredValidationMessage(whiteListedDomains[0])
    : getRequiredValidationMessage(whiteListedDomains);
};

export const validateTokenValues: TokenValidationFunction = (tokenValues) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    expiresAt,
    email,
    name,
    scopes,
    tokenType,
    storeId,
    whiteListedDomains,
  } = tokenValues;
  const emailValidationMessage = getRequiredValidationMessage(email);
  const expiresAtValidationMessage = getRequiredValidationMessage(expiresAt);
  const nameValidationMessage = getRequiredValidationMessage(name);
  const scopesValidationMessage = getRequiredValidationMessage(scopes);
  const tokenTypeValidationMessage = getRequiredValidationMessage(tokenType);
  const storeIdValidationMessage =
    tokenType === STORE_LEVEL_ACCESS_TYPE
      ? getStoreIdValidationMessage(storeId)
      : null;
  const whiteListedDomainsMessage =
    getWhiteListedDomainsValidationMessage(whiteListedDomains);

  return emailValidationMessage ||
    expiresAtValidationMessage ||
    nameValidationMessage ||
    tokenTypeValidationMessage ||
    scopesValidationMessage ||
    storeIdValidationMessage ||
    whiteListedDomainsMessage
    ? {
        email: emailValidationMessage,
        expiresAt: expiresAtValidationMessage,
        name: nameValidationMessage,
        scopes: scopesValidationMessage,
        tokenType: tokenTypeValidationMessage,
        storeId: storeIdValidationMessage,
        whiteListedDomains: whiteListedDomainsMessage,
      }
    : null;
};
const hasProperty = <Obj, Prop extends string>(
  obj: Obj,
  prop: Prop
): obj is Obj & Record<Prop, unknown> =>
  Object.prototype.hasOwnProperty.call(obj, prop);

export function validatePrintfulStoreConnectionType(
  value: unknown
): asserts value is { stores: PrintfulStoreConnection } {
  if (!value || typeof value !== 'object') {
    throw new TypeError('Type error: value not object');
  }

  if (!hasProperty(value, 'stores')) {
    throw new TypeError('Type error: value has no stores value');
  }

  if (!hasProperty(value.stores, 'edges')) {
    throw new TypeError('Type error: stores have no edges property');
  }

  if (!hasProperty(value.stores, 'pageInfo')) {
    throw new TypeError('Type error: stores have no pageInfo property');
  }
}
