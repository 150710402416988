import { mdiFacebook, mdiTwitter, mdiInstagram, mdiYoutube } from '@mdi/js';
export interface IPlatform {
  href: string;
  icon: string;
  background: string;
  tooltip: {
    text: string;
    position: string;
  };
}

export const SocialMediaData: Array<IPlatform> = [
  {
    href: 'https://www.facebook.com/printful',
    icon: mdiFacebook,
    background: 'hover:bg-pf-nice-blue',
    tooltip: {
      text: 'Facebook',
      position: 'bottom',
    },
  },
  {
    href: 'https://www.twitter.com/printful',
    icon: mdiTwitter,
    background: 'hover:bg-blue-400',
    tooltip: {
      text: 'Twitter',
      position: 'bottom',
    },
  },
  {
    href: 'https://www.instagram.com/printful/',
    icon: mdiInstagram,
    background: 'hover:bg-black',
    tooltip: {
      text: 'Instagram',
      position: 'bottom',
    },
  },
  {
    href: 'https://www.youtube.com/channel/UCnzsJ4Wvmug35USak9mGx3w',
    icon: mdiYoutube,
    background: 'hover:bg-red-600',
    tooltip: {
      text: 'Youtube',
      position: 'bottom',
    },
  },
];
