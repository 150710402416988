



















import Vue from 'vue';
import { mdiAccountOutline, mdiChevronDown } from '@mdi/js';

export default Vue.extend({
  name: 'AccountDropdown',

  props: {
    userName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      state: false,
      accountIcon: mdiAccountOutline,
      chevronIcon: mdiChevronDown,
    };
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    close(e: Event) {
      if (!this.$el.contains(e.target as Node)) {
        this.state = false;
      }
    },

    open(): void {
      this.state = true;
    },

    switchState(): void {
      this.state = !this.state;
    },
  },
});
