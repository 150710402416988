import { gql } from '@apollo/client/core';

export const storesQuery = gql`
  query devPortal($offset: UInt, $limit: PositiveInt) {
    devPortal {
      stores: devPortalPrintfulStores(offset: $offset, limit: $limit) {
        edges {
          node {
            id
            storeId
            name
            type
          }
        }
        pageInfo {
          limit
          offset
          total
          totalPages
          currentPage
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
