import Vue from 'vue';
import VueApollo from '@ideabits/vue-apollo';
import { createDefaultClient } from '@ideabits/nuxt-apollo-module/dist/services/apolloClientInitializer';

/**
 * Plugin that sets up vue-apollo v4 for this app
 */

Vue.use(VueApollo);

const plugin = async (ctx) => {
  const { app, ssrContext } = ctx;

  // Initialize options resolver & execute it
  const optionsResolver = require('~/src/core/_/bootstrapping/config/apolloOptionsResolver.ts').default;
  const { clientOptions, watchLoading, errorHandler } = await Promise.resolve(
    optionsResolver(ctx)
  );

  // Create client
  const client = createDefaultClient(clientOptions);

  // Create and register provider
  const apolloProvider = new VueApollo({
    defaultClient: client,
    watchLoading,
    errorHandler,
  });
  app.apolloProvider = apolloProvider;

  // Hacky, but it works: storing reference to main provider in a hidden property
  // so that we can access it post-render to get Vue Apollo state in a 'vue-renderer:ssr:context' hook
  // TODO: We can get of this once nuxt releases this PR https://github.com/nuxt/nuxt.js/pull/9332
  if (process.server) {
    Object.defineProperty(ssrContext, '__APOLLO_PROVIDER', {
      value: apolloProvider,
      configurable: true,
    });
  }
};

export default plugin;
