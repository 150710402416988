import { Store } from 'vuex';
import { ApolloClient } from '~/node_modules/@apollo/client/core';
import { InMemoryCache } from '~/node_modules/@apollo/client/cache/inmemory/inMemoryCache';
import { Edge, PrintfulStoreConnection, PrintfulStoreEdge } from '~/src/core/graphql/generated/types';
import { storesQuery } from '~/src/printful/graphql/printful';
import { validatePrintfulStoreConnectionType } from '~/src/tokens/validation';

export const state = (): { stores: PrintfulStoreConnection } => ({
  stores: {
    edges: [],
    pageInfo: {
      __typename: 'OffsetBasedPageInfo',
      limit: 20,
      offset: 0,
      total: 0,
      totalPages: 0,
      currentPage: 0,
      hasNextPage: false,
      hasPreviousPage: false,
    },
  },
});

export const mutations = {
  setStores(
    state: { stores: PrintfulStoreConnection },
    newStores: PrintfulStoreConnection
  ): void {
    state.stores = newStores;
  },
  pushStores(
    state: { stores: PrintfulStoreConnection },
    storeConnection: { stores: PrintfulStoreConnection }
  ): void {
    type edgeType = Edge & PrintfulStoreEdge;

    state.stores = {
      edges: [
        ...state.stores.edges,
        ...storeConnection.stores.edges.filter(
          (edge: edgeType) => edge.node.type !== 'quick-stores'
        )
      ],
      pageInfo: storeConnection.stores.pageInfo,
    };
  },
};

export const actions = {
  async fetchFirstStores(
    state: Store<{ stores: PrintfulStoreConnection }>,
    payload: {
      client: ApolloClient<InMemoryCache>;
      pagingInput: { offset: number; limit: number };
    }
  ): Promise<void> {
    const res = await payload.client.query<{ devPortal: PrintfulStoreConnection }>({
      query: storesQuery,
      variables: payload.pagingInput,
    });

    if (res.error) {
      throw res.error;
    }
    validatePrintfulStoreConnectionType(res.data.devPortal);
    state.commit('pushStores', res.data.devPortal);
  },
  async lazyFetchAllStores(
    state: Store<{ stores: PrintfulStoreConnection }>,
    payload: {
      client: ApolloClient<InMemoryCache>;
      pagingInput: { offset: number; limit: number };
    }
  ): Promise<void> {
    const { pagingInput, client } = payload;
    const newStores = { ...state.state.stores };
    while (newStores.pageInfo.hasNextPage) {
      pagingInput.offset += 20;
      const res = await client.query<{ devPortal: PrintfulStoreConnection }>({
        query: storesQuery,
        variables: pagingInput,
      });
      if (res.error) {
        throw res.error;
      }

      validatePrintfulStoreConnectionType(res.data.devPortal);
      newStores.edges = [...newStores.edges, ...res.data.devPortal.stores.edges];
      newStores.pageInfo = res.data.devPortal.stores.pageInfo;
    }
    state.commit('setStores', newStores);
  },
};
