import { mdiLogoutVariant } from '@mdi/js';
import 'reflect-metadata';
import { Container } from 'inversify';
import { ClientAuthService } from '~/src/core/_/auth/services/clientAuthService';
import { GQLFetchRequest } from '~/src/common/gqlFetchRequest';
import { logoutRequest } from '~/src/user/repository';

export interface IDropdownOptions {
  id: string;
  name: string;
  icon: string;
  href: (container: Container) => string;
  gqlFetchRequest?: GQLFetchRequest<void>;
}

// dropdown tree for user options
export const dropdownOptions: Array<IDropdownOptions> = [
  {
    id: 'logout',
    name: 'Log out from Printful',
    icon: mdiLogoutVariant,
    href: (container: Container): string => {
      return container.get(ClientAuthService).getOauthInvalidateUrl();
    },
    gqlFetchRequest: logoutRequest,
  },
];
