import { ApolloClient } from '~/node_modules/@apollo/client/core';
import { InMemoryCache } from '~/node_modules/@apollo/client/cache/inmemory/inMemoryCache';
import { devPortalPrintfulMetaDataFetchResult } from '~/src/devPortal/fetchResults';
import { getTotalFulfilledProductCount } from '~/src/meta/gql';

export async function fetchFulfilledProductCount(
  client: ApolloClient<InMemoryCache>
): Promise<number | null> {
  const { data }: devPortalPrintfulMetaDataFetchResult = await client.query({
    query: getTotalFulfilledProductCount,
  });
  const productCount =
    data?.devPortal.devPortalPrintfulMetadata.fulfilledProductCount;

  if (!productCount) {
    return null;
  }

  return productCount;
}
