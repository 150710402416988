import {
  mdiKeyOutline,
  mdiBullhornOutline,
  mdiShapeSquareRoundedPlus,
  mdiTextBoxOutline,
} from '@mdi/js';
export interface Page {
  id: string;
  name: string;
  icon: string;
  to: string;
  current: boolean;
}

export const menu: Array<Page> = [
  {
    id: 'apps',
    name: 'Apps',
    icon: mdiShapeSquareRoundedPlus,
    to: '/apps',
    current: false,
  },
  {
    id: 'tokens',
    name: 'Tokens',
    icon: mdiKeyOutline,
    to: '/tokens',
    current: false,
  },
  {
    id: 'news',
    name: 'News',
    icon: mdiBullhornOutline,
    to: '/news',
    current: false,
  },
];

export const outerLinks: Page[] = [
  {
    id: 'docs',
    name: 'Docs',
    icon: mdiTextBoxOutline,
    to: 'https://developers.printful.com/docs',
    current: false,
  },
];
