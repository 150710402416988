/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Vue from 'vue';

Vue.directive('tooltip', {
  bind: (el, binding) => {
    el.dataset.label = binding.value.text;
    el.dataset.microtipPosition = binding.value.position;
    el.dataset.microtipSize = binding.value.size;
    el.dataset.microtipColorMode = binding.value.colorMode;
    el.setAttribute('role', 'tooltip');
  },
});