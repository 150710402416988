






import Vue from 'vue';

export default Vue.extend({
  name: 'Icon',
  props: {
    path: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: 24,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    viewBoxSize(): string {
      return '0 0 24 24';
    },
  },
});
