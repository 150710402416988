import { Optional } from '~/src/core/types/coreUtilityTypes';

export abstract class BaseError extends Error {
  /**
   * Extend this to set a code for your error
   */
  static CODE = 'BOOTSTRAP_ERROR';

  /**
   * Extend this to set a default message for your error
   */
  static DEFAULT_MESSAGE =
    'An issue occurred while bootstrapping the application!';

  /**
   * Extend this to store random stuff in your errors
   */
  options: Record<string, unknown>;

  #code: string;

  constructor(
    message?: Optional<string>,
    options?: Optional<Record<string, unknown>>
  ) {
    super(message || new.target.DEFAULT_MESSAGE);
    this.options = options || {};
    this.#code = new.target.CODE;
  }

  get code(): string {
    return this.#code;
  }
}

export class BootstrapError extends BaseError {
  static CODE = 'BOOTSTRAP_ERROR';
  static DEFAULT_MESSAGE =
    'An issue occurred while bootstrapping the application!';
}
