import { Maybe } from '~/src/core/types/coreUtilityTypes';

export const REQUIRED_MESSAGE = '* required';

const INVALID_EMAIL_MESSAGE = '* email address is not valid';

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\..+$/;

export const getRequiredValidationMessage = (
  input: Maybe<string> | Maybe<string>[]
): Maybe<string> =>
  !input || (input && input.length < 1) ? REQUIRED_MESSAGE : null;

export const getEmailValidation = (email: string): Maybe<string> => {
  const empty = getRequiredValidationMessage(email);

  if (empty) {
    return empty;
  }

  return !EMAIL_REGEX.exec(email) ? INVALID_EMAIL_MESSAGE : null;
};
