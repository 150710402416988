



































import Vue from 'vue';
import { mdiMenu, mdiAccountOutline } from '@mdi/js';
import { IIconData } from '~/src/components/layout/interfaces';

export default Vue.extend({
  name: 'Navbar',
  props: {
    userName: {
      type: String,
      required: false,
      default: 'User',
    },
  },
  data() {
    return {
      menuIcon: {
        path: mdiMenu,
        size: 36,
      } as IIconData,
      userIcon: {
        path: mdiAccountOutline,
        size: 24,
      } as IIconData,
    };
  },
  computed: {
    name(): string {
      const name = this.userName.split(' ');
      return name[0];
    },
  },
});
