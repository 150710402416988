import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  SvgIcon: () => import('../../node_modules/@jamescoyle/vue-icon/lib/svg-icon.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c)),
  CommonBlankState: () => import('../../components/common/BlankState.vue' /* webpackChunkName: "components/common-blank-state" */).then(c => wrapFunctional(c.default || c)),
  CommonBreadcrumb: () => import('../../components/common/Breadcrumb.vue' /* webpackChunkName: "components/common-breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  CommonChecklist: () => import('../../components/common/Checklist.vue' /* webpackChunkName: "components/common-checklist" */).then(c => wrapFunctional(c.default || c)),
  CommonChecklistItem: () => import('../../components/common/ChecklistItem.vue' /* webpackChunkName: "components/common-checklist-item" */).then(c => wrapFunctional(c.default || c)),
  CommonCookiesBanner: () => import('../../components/common/CookiesBanner.vue' /* webpackChunkName: "components/common-cookies-banner" */).then(c => wrapFunctional(c.default || c)),
  CommonDeleteConfirmationModal: () => import('../../components/common/DeleteConfirmationModal.vue' /* webpackChunkName: "components/common-delete-confirmation-modal" */).then(c => wrapFunctional(c.default || c)),
  CommonDivider: () => import('../../components/common/Divider.vue' /* webpackChunkName: "components/common-divider" */).then(c => wrapFunctional(c.default || c)),
  CommonIcon: () => import('../../components/common/Icon.vue' /* webpackChunkName: "components/common-icon" */).then(c => wrapFunctional(c.default || c)),
  CommonInfoBanner: () => import('../../components/common/InfoBanner.vue' /* webpackChunkName: "components/common-info-banner" */).then(c => wrapFunctional(c.default || c)),
  CommonInputWithCopyToClipboard: () => import('../../components/common/InputWithCopyToClipboard.vue' /* webpackChunkName: "components/common-input-with-copy-to-clipboard" */).then(c => wrapFunctional(c.default || c)),
  CommonListInput: () => import('../../components/common/ListInput.vue' /* webpackChunkName: "components/common-list-input" */).then(c => wrapFunctional(c.default || c)),
  CommonSnackbar: () => import('../../components/common/Snackbar.vue' /* webpackChunkName: "components/common-snackbar" */).then(c => wrapFunctional(c.default || c)),
  CommonSpinnerOverlay: () => import('../../components/common/SpinnerOverlay.vue' /* webpackChunkName: "components/common-spinner-overlay" */).then(c => wrapFunctional(c.default || c)),
  CommonTooltipTag: () => import('../../components/common/TooltipTag.vue' /* webpackChunkName: "components/common-tooltip-tag" */).then(c => wrapFunctional(c.default || c)),
  CommonVContainer: () => import('../../components/common/VContainer.vue' /* webpackChunkName: "components/common-v-container" */).then(c => wrapFunctional(c.default || c)),
  CommonValidationTag: () => import('../../components/common/ValidationTag.vue' /* webpackChunkName: "components/common-validation-tag" */).then(c => wrapFunctional(c.default || c)),
  CommonWarningBanner: () => import('../../components/common/WarningBanner.vue' /* webpackChunkName: "components/common-warning-banner" */).then(c => wrapFunctional(c.default || c)),
  AppsDeleteApp: () => import('../../components/apps/DeleteApp.vue' /* webpackChunkName: "components/apps-delete-app" */).then(c => wrapFunctional(c.default || c)),
  AppsForm: () => import('../../components/apps/Form.vue' /* webpackChunkName: "components/apps-form" */).then(c => wrapFunctional(c.default || c)),
  AppsMobileTable: () => import('../../components/apps/MobileTable.vue' /* webpackChunkName: "components/apps-mobile-table" */).then(c => wrapFunctional(c.default || c)),
  AppsTable: () => import('../../components/apps/Table.vue' /* webpackChunkName: "components/apps-table" */).then(c => wrapFunctional(c.default || c)),
  HomeCard: () => import('../../components/home/Card.vue' /* webpackChunkName: "components/home-card" */).then(c => wrapFunctional(c.default || c)),
  LoginTheLoginSideList: () => import('../../components/login/TheLoginSideList.vue' /* webpackChunkName: "components/login-the-login-side-list" */).then(c => wrapFunctional(c.default || c)),
  NewsItem: () => import('../../components/news/Item.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c)),
  NewsList: () => import('../../components/news/List.vue' /* webpackChunkName: "components/news-list" */).then(c => wrapFunctional(c.default || c)),
  LayoutAccountDropdown: () => import('../../components/layout/AccountDropdown.vue' /* webpackChunkName: "components/layout-account-dropdown" */).then(c => wrapFunctional(c.default || c)),
  LayoutDrawer: () => import('../../components/layout/Drawer.vue' /* webpackChunkName: "components/layout-drawer" */).then(c => wrapFunctional(c.default || c)),
  LayoutFooter: () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c)),
  LayoutNavbar: () => import('../../components/layout/Navbar.vue' /* webpackChunkName: "components/layout-navbar" */).then(c => wrapFunctional(c.default || c)),
  LayoutSideMenu: () => import('../../components/layout/SideMenu.vue' /* webpackChunkName: "components/layout-side-menu" */).then(c => wrapFunctional(c.default || c)),
  LayoutUserMenu: () => import('../../components/layout/UserMenu.vue' /* webpackChunkName: "components/layout-user-menu" */).then(c => wrapFunctional(c.default || c)),
  TokensDesktopTable: () => import('../../components/tokens/DesktopTable.vue' /* webpackChunkName: "components/tokens-desktop-table" */).then(c => wrapFunctional(c.default || c)),
  TokensForm: () => import('../../components/tokens/Form.vue' /* webpackChunkName: "components/tokens-form" */).then(c => wrapFunctional(c.default || c)),
  TokensResponsiveTable: () => import('../../components/tokens/ResponsiveTable.vue' /* webpackChunkName: "components/tokens-responsive-table" */).then(c => wrapFunctional(c.default || c)),
  TokensV2Beta: () => import('../../components/tokens/V2Beta.vue' /* webpackChunkName: "components/tokens-v2-beta" */).then(c => wrapFunctional(c.default || c)),
  AppsFormAccess: () => import('../../components/apps/form/Access.vue' /* webpackChunkName: "components/apps-form-access" */).then(c => wrapFunctional(c.default || c)),
  AppsFormCredentials: () => import('../../components/apps/form/Credentials.vue' /* webpackChunkName: "components/apps-form-credentials" */).then(c => wrapFunctional(c.default || c)),
  AppsFormDetails: () => import('../../components/apps/form/Details.vue' /* webpackChunkName: "components/apps-form-details" */).then(c => wrapFunctional(c.default || c)),
  AppsFormIFormData: () => import('../../components/apps/form/IFormData.ts' /* webpackChunkName: "components/apps-form-i-form-data" */).then(c => wrapFunctional(c.default || c)),
  AppsFormResetKey: () => import('../../components/apps/form/ResetKey.vue' /* webpackChunkName: "components/apps-form-reset-key" */).then(c => wrapFunctional(c.default || c)),
  AppsFormScopes: () => import('../../components/apps/form/Scopes.vue' /* webpackChunkName: "components/apps-form-scopes" */).then(c => wrapFunctional(c.default || c)),
  AppsFormURLs: () => import('../../components/apps/form/URLs.vue' /* webpackChunkName: "components/apps-form-u-r-ls" */).then(c => wrapFunctional(c.default || c)),
  TokensFormAccessKey: () => import('../../components/tokens/form/AccessKey.vue' /* webpackChunkName: "components/tokens-form-access-key" */).then(c => wrapFunctional(c.default || c)),
  TokensFormDomains: () => import('../../components/tokens/form/Domains.vue' /* webpackChunkName: "components/tokens-form-domains" */).then(c => wrapFunctional(c.default || c)),
  TokensFormExtensions: () => import('../../components/tokens/form/Extensions.vue' /* webpackChunkName: "components/tokens-form-extensions" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
