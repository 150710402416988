import { GraphQLError, GraphQLFormattedError } from 'graphql';
import {
  ApolloError,
  ServerParseError,
  ServerError,
} from '@apollo/client/core';

export type FullApolloErrorMetadata = {
  isInternalError: boolean;
  operationErrors:
    | ReadonlyArray<GraphQLError>
    | Array<GraphQLFormattedError>
    | undefined;
  error: Error | undefined;
  networkError: Error | ServerParseError | ServerError | undefined;
};

function isServerError(err: Error): err is ServerError {
  return err.name === 'ServerError';
}

/**
 * Build an object containing full error metadata (easier to work with in some
 * scenarios)
 */
export function buildFullErrorMetadata(
  originalError: ApolloError
): FullApolloErrorMetadata {
  const { networkError, graphQLErrors } = originalError || {};

  // Is internal server error?
  const isInternalError = !!(graphQLErrors || []).find(
    (e) =>
      e.extensions?.category === 'internal' ||
      /internal server error/i.exec(e.message || '')
  );

  let operationErrors: FullApolloErrorMetadata['operationErrors'] =
    (graphQLErrors || []).length > 0 ? graphQLErrors : undefined;

  // Maybe graphQLErrors wasn't filled, but response still had them? https://github.com/apollographql/apollo-client/issues/6222
  if (
    !operationErrors &&
    networkError &&
    isServerError(networkError) &&
    networkError.result?.errors
  ) {
    operationErrors = networkError.result
      .errors as Array<GraphQLFormattedError>;
  }

  return {
    networkError: networkError || undefined,
    operationErrors: operationErrors || undefined,
    isInternalError,
    error: originalError || undefined,
  };
}
