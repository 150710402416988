















































import Vue from 'vue';
import { config } from '~/src/core/utils/smartRuntimeConfig';

export default Vue.extend({
  name: 'CookiesBanner',
  data() {
    return {
      showBanner: false,
    };
  },
  mounted(): void {
    const cookiePreference = this.getCookie('_pf_dev_cookie_pref');

    if (!cookiePreference) {
      this.showBanner = true;
    }
  },
  methods: {
    getCookie(name: string): string | null {
      const cookie = document.cookie
        .split(';')
        .map((c) => c.trim())
        .find((c) => c.startsWith(`${name}=`));

      if (!cookie) {
        return null;
      }
      const [, value] = cookie.split('=');

      return decodeURIComponent(value);
    },
    acceptAll(): void {
      const { baseUrl } = config(this.$config);
      void this.$axios.get(`${baseUrl}api/acceptCookiesAll`);
      const {
        gtm: { id },
      } = config(this.$config);
      this.$gtm.init(id);
      this.showBanner = false;
    },
    acceptMandatory(): void {
      const { baseUrl } = config(this.$config);
      void this.$axios.get(`${baseUrl}api/acceptCookiesMandatory`);
      this.showBanner = false;
    },
  },
});
