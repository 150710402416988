



































import Vue from 'vue';
import { mdiClose } from '@mdi/js';

export default Vue.extend({
  name: 'InfoBanner',
  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    callToAction: {
      type: String,
      required: true,
    },
    ctaLink: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      mdiClose,
      showBanner: false,
    };
  },
  mounted(): void {
      this.showBanner = this.shouldShowBanner();
  },
  methods: {
    shouldShowBanner(): boolean {
      if (this.$isServer) {
        return false;
      }

      return localStorage.getItem(this.getHideBannerKey()) !== '1';
    },
    close(): void {
      this.showBanner = false;
      localStorage.setItem(this.getHideBannerKey(), '1');
    },
    getHideBannerKey(): string {
      return `_pf_hide_banner-${this.id}`;
    },
  },
});
