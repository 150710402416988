import TInput from 'vue-tailwind/dist/t-input';
import TButton from 'vue-tailwind/dist/t-button';
import TTextarea from 'vue-tailwind/dist/t-textarea';
import TTag from 'vue-tailwind/dist/t-tag';
import TTable from 'vue-tailwind/dist/t-table';
import TPagination from 'vue-tailwind/dist/t-pagination';
import TRadioGroup from 'vue-tailwind/dist/t-radio-group';
import TDatepicker from 'vue-tailwind/dist/t-datepicker';
import TCheckboxGroup from 'vue-tailwind/dist/t-checkbox-group';
import TAlert from 'vue-tailwind/dist/t-alert';
import TSelect from 'vue-tailwind/dist/t-select';
import TModal from 'vue-tailwind/dist/t-modal';
import TCheckbox from 'vue-tailwind/dist/t-checkbox';

export const SETTINGS = {
  't-input': {
    component: TInput,
    props: {
      classes:
        'border rounded-[5px] border-pf-light-gray w-full text-pf-dark block focus:ring-2 focus:ring-pf-nice-blue focus:outline-none focus:ring-opacity-50 disabled:opacity-50',
    },
  },
  't-textarea': {
    component: TTextarea,
    props: {
      classes: 'border border-pf-light-gray w-full text-pf-dark block',
    },
  },
  't-tag': {
    component: TTag,
    props: {
      fixedClasses: '',
      classes: '',
      variants: {
        super: 'text-h1 font-bold',
        heading: 'text-h2 font-bold',
        title: 'text-h3 font-bold',
        subheading: 'text-subheading font-bold',
        body: 'text-body',
        caption: 'text-caption',
        legal: 'text-legal',
        error: 'text-red-500',
        badge:
          'inline-flex items-center px-3 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800',
        avatar:
          'inline-flex items-center justify-center h-40 w-40 rounded-full bg-gray-500 overflow-hidden leading-none text-center',
      },
    },
  },
  't-button': {
    component: TButton,
    props: {
      fixedClasses:
        'block text-center disabled:opacity-50 disabled:cursor-not-allowed',
      classes:
        'px-24 py-8 text-white font-bold w-full bg-pf-nice-blue rounded hover:shadow-md hover:ring-1 hover:ring-black active:shadow-inner',
      variants: {
        secondary:
          'px-24 py-8 text-pf-dark font-bold w-full bg-pf-light-gray rounded hover:shadow-md hover:ring-1 hover:ring-pf-neutral-light-gray active:shadow-inner',
        error:
          'text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600',
        success:
          'text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600',
        link: 'text-blue-500 underline hover:text-blue-600',
        circle:
          'text-white text-h2 font-bold bg-pf-nice-blue shadow-sm rounded-full min-w-32 h-32 text-center',
      },
    },
  },
  't-table': {
    component: TTable,
    props: {
      classes: {
        table: 'min-w-full divide-y divide-pf-light-gray',
        thead: '',
        theadTr: 'border-b-2',
        theadTh: 'px-3 py-12 font-semibold text-left bg-white border-b',
        tbody: 'bg-white divide-y divide-pf-light-gray',
        tr: '',
        td: 'px-3 py-16',
        tfoot: '',
        tfootTr: '',
        tfootTd: '',
      },
      variants: {
        thin: {
          td: 'p-1 whitespace-no-wrap text-sm',
          theadTh: 'p-1 font-semibold text-left bg-gray-100 border-b text-sm',
        },
      },
    },
  },
  't-pagination': {
    component: TPagination,
    props: {
      classes: {
        wrapper: 'flex text-center bg-white mx-auto justify-center space-x-4',
        element:
          'w-40 h-40 border border-pf-light-gray text-pf-muted-gray rounded',
        activeElement:
          'w-40 h-40 border text-pf-muted-gray border-pf-muted-gray rounded',
        disabledElement: 'w-40 h-40 border border-pf-muted-gray rounded',
        ellipsisElement: 'w-40 h-40 border border-pf-muted-gray rounded hidden',
        activeButton:
          'w-full h-full text-pf-muted-gray transition duration-100 ease-in-out focus:outline-none focus:ring-opacity-50',
        disabledButton:
          'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
        button:
          'w-full h-full rounded transition duration-100 ease-in-out focus:outline-none focus:ring-opacity-50',
        ellipsis: '',
      },
    },
  },
  't-radio-group': {
    component: TRadioGroup,
    props: {
      classes: {
        groupWrapper: 'flex flex-col',
        label: 'ml-8 text-pf-dark text-body hover:cursor-pointer',
        input:
          'text-pf-nice-blue transition duration-100 ease-in-out border-pf-light-gray shadow-sm focus:border-pf-nice-blue focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed hover:cursor-pointer',
      },
      variants: {
        danger: {
          groupWrapper: 'flex flex-col',
          label: 'ml-8 text-red-500 text-body',
          input:
            'text-red-500 transition duration-100 ease-in-out border-red-500 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed',
        },
        buttons: {
          groupWrapper: 'flex',
          label: '',
          labelChecked: '',
          wrapper:
            'mx-1 bg-white border border-pf-light-gray flex items-center px-4 py-2 rounded shadow-sm cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          wrapperChecked:
            'mx-1 bg-gray-100 border border-pf-light-gray flex items-center px-4 py-2 rounded shadow-inner cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          inputWrapper: '',
          inputWrapperChecked: '',
          input: 'absolute invisible',
        },
      },
    },
  },
  't-checkbox-group': {
    component: TCheckboxGroup,
    props: {
      classes: {
        groupWrapper: 'flex flex-col',
        label: 'ml-8 text-pf-dark text-body hover:cursor-pointer',
        input:
          'text-pf-nice-blue transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 hover:cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed border-gray-300',
      },
      variants: {
        danger: {
          groupWrapper: 'flex flex-col',
          label: 'ml-8 text-red-500 text-body',
          input:
            'transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed text-red-500 border-red-500',
        },
        buttons: {
          groupWrapper: 'flex',
          label: '',
          labelChecked: '',
          wrapper:
            'mx-1 bg-white border border-pf-light-gray flex items-center px-4 py-2 rounded shadow-sm cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          wrapperChecked:
            'mx-1 bg-gray-100 border border-pf-light-gray flex items-center px-4 py-2 rounded shadow-inner cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
          inputWrapper: '',
          inputWrapperChecked: '',
          input: 'absolute invisible',
        },
      },
    },
  },
  't-datepicker': {
    component: TDatepicker,
    props: {
      fixedClasses: {
        navigator: 'flex',
        navigatorViewButton: 'flex items-center',
        navigatorViewButtonIcon: 'flex-shrink-0 h-20 w-20',
        navigatorViewButtonBackIcon: 'flex-shrink-0 h-20 w-20',
        navigatorLabel: 'flex items-center py-4',
        navigatorPrevButtonIcon: 'h-24 w-24 inline-flex',
        navigatorNextButtonIcon: 'h-24 w-24 inline-flex',
        inputWrapper: 'relative',
        viewGroup: 'inline-flex flex-wrap',
        view: 'w-full',
        calendarDaysWrapper: 'grid grid-cols-7 pb-12',
        calendarHeaderWrapper: 'grid grid-cols-7',
        monthWrapper: 'grid grid-cols-4',
        yearWrapper: 'grid grid-cols-4',
        input:
          'block w-full px-12 py-8 transition duration-100 ease-in-out border rounded shadow-sm focus:border-pf-nice-blue focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        clearButton:
          'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-8 h-24 w-24',
        clearButtonIcon: 'fill-current h-12 w-12',
      },
      classes: {
        wrapper: 'flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown:
          'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-4',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
        inlineWrapper: '',
        inlineViews: 'rounded bg-white border mt-4 inline-flex',
        inputWrapper: '',
        input: 'text-black placeholder-gray-400 border-gray-300',
        clearButton:
          'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
        clearButtonIcon: '',
        viewGroup: '',
        view: '',
        navigator: 'pt-8 px-12',
        navigatorViewButton:
          'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-8 py-4 -ml-4 hover:bg-gray-100',
        navigatorViewButtonIcon: 'fill-current text-gray-400',
        navigatorViewButtonBackIcon: 'fill-current text-gray-400',
        navigatorViewButtonMonth: 'text-gray-700 font-semibold',
        navigatorViewButtonYear: 'text-gray-500 ml-4',
        navigatorViewButtonYearRange: 'text-gray-500 ml-4',
        navigatorLabel: 'py-4',
        navigatorLabelMonth: 'text-gray-700 font-semibold',
        navigatorLabelYear: 'text-gray-500 ml-4',
        navigatorPrevButton:
          'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-4 ml-8 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorNextButton:
          'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-4 -mr-4 disabled:opacity-50 disabled:cursor-not-allowed',
        navigatorPrevButtonIcon: 'text-gray-400',
        navigatorNextButtonIcon: 'text-gray-400',
        calendarWrapper: 'px-12 pt-8',
        calendarHeaderWrapper: '',
        calendarHeaderWeekDay:
          'uppercase text-legal text-gray-500 w-32 h-32 flex items-center justify-center',
        calendarDaysWrapper: '',
        calendarDaysDayWrapper:
          'w-full h-32 flex flex-shrink-0 items-center border',
        otherMonthDay:
          'text-sm rounded-full w-32 h-32 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
        emptyDay: '',
        inRangeFirstDay:
          'text-sm bg-blue-500 text-white w-full h-32 rounded-l-full',
        inRangeLastDay:
          'text-sm bg-blue-500 text-white w-full h-32 rounded-r-full',
        inRangeDay:
          'text-sm bg-blue-200 w-full h-32 disabled:opacity-50 disabled:cursor-not-allowed',
        selectedDay:
          'text-sm rounded-full w-32 h-32 mx-auto bg-pf-nice-blue text-white disabled:opacity-50 disabled:cursor-not-allowed',
        activeDay:
          'text-sm rounded-full bg-blue-100 w-32 h-32 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        highlightedDay:
          'text-sm rounded-full bg-blue-200 w-32 h-32 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
        day: 'text-sm rounded-full w-32 h-32 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed',
        today:
          'text-sm rounded-full w-32 h-32 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500',
        monthWrapper: 'px-12 pt-8',
        selectedMonth:
          'text-sm rounded w-full h-48 mx-auto bg-blue-500 text-white',
        activeMonth: 'text-sm rounded w-full h-48 mx-auto bg-blue-100',
        month: 'text-sm rounded w-full h-48 mx-auto hover:bg-blue-100',
        yearWrapper: 'px-12 pt-4',
        year: 'text-sm rounded w-full h-48 mx-auto hover:bg-blue-100',
        selectedYear:
          'text-sm rounded w-full h-48 mx-auto bg-blue-500 text-white',
        activeYear: 'text-sm rounded w-full h-48 mx-auto bg-blue-100',
      },
      variants: {
        danger: {
          input: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
          clearButton: 'hover:bg-red-200 text-red-500',
        },
      },
    },
  },
  't-alert': {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'py-16 px-24',
        body: 'text-body',
        close: '',
        closeIcon: '',
      },
      classes: {
        wrapper: 'bg-blue-50 border-blue-500',
        body: 'text-blue-700',
        close: 'text-blue-500 hover:bg-blue-200',
      },
      variants: {
        danger: {
          wrapper:
            'fixed w-full sm:w-auto sm:top-40 sm:left-1/3 z-50 bg-red-600 rounded',
          body: 'text-white text-center',
          close: '',
        },
        success: {
          wrapper:
            'fixed w-full sm:w-auto sm:top-40 sm:left-1/2 z-50 bg-green-800-success rounded',
          body: 'text-white text-center',
          close: '',
        },
        successRealCenter: {
          wrapper: 'fixed z-50 bg-green-800-success rounded',
          body: 'text-white text-center',
          close: '',
        },
        info: {
          wrapper:
            'fixed w-full sm:w-auto sm:top-40 sm:left-1/2 z-50 bg-pf-nice-blue rounded',
          body: 'text-white text-center',
          close: '',
        },
        warning: {
          wrapper: 'relative shadow-sm bg-yellow-50 border-yellow-600',
          body: 'text-yellow-600',
          close: 'text-pf-muted-gray',
        },
      },
    },
  },
  't-select': {
    component: TSelect,
    props: {
      fixedClasses:
        'block w-full pl-12 pr-40 py-8 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      classes:
        'text-pf-dark placeholder-gray-400 bg-white border-gray-300 focus:border-pf-nice-blue hover:cursor-pointer',
      variants: {
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success:
          'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
      },
    },
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay:
          'z-50  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'relative mx-auto z-50 max-w-lg px-12 py-48',
        modal: 'overflow-visible relative',
        body: '',
        header: 'border-b',
        footer: 'border-t',
        close:
          'flex items-center justify-center absolute right-0 top-0 -m-12 h-32 w-32 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'bg-white shadow',
        body: '',
        header: 'border-gray-100',
        footer: 'bg-white',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-16 w-16',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close:
            'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50',
        },
      },
    },
  },
  't-checkbox': {
    component: TCheckbox,
    props: {
      fixedClasses: {
        input: 't-checkbox',
      },
      classes: {
        input: 'hover:cursor-pointer',
        label: 'ml-8 text-body font-bold hover:cursor-pointer',
        inputWrapper: 'inline-flex',
        wrapper: 'flex flex-row items-center',
      },
      variants: {
        error: 'text-red-500 border-red-300',
        success: 'text-green-500 border-green-300',
      },
    },
  },
};
